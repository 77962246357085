import classNames from "classnames";
import { Link } from "gatsby";
import { useCallback, useEffect, useState } from "react";
import { ContentContainer } from "~/components/atoms/ContentContainer";
import { MdMenu, MdClose } from "react-icons/md";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const NavLi = ({
  children,
  className = "",
  to,
  onClick,
}: {
  children: React.ReactNode;
  className?: string;
  to: string;
  onClick?: () => void;
}) => {
  const { md: isMd } = useBreakpoint();

  return (
    // border-b-2
    <li className={classNames("mx-2 text-xl", !isMd && "my-2", className)}>
      <Link to={to} onClick={onClick}>
        {children}
      </Link>
    </li>
  );
};

export const GlobalHeader = ({ className = "" }: { className?: string }) => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    setReady(true);
  }, []);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { md: isMd } = useBreakpoint();

  useEffect(() => {
    if (isMd) {
      setIsMobileMenuOpen(false);
    }
  }, [isMd]);

  const closeMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(false);
  }, []);

  return (
    <header
      className={classNames("fixed top-6 left-0 right-0 px-3", className)}
    >
      <ContentContainer
        className={classNames(
          "shadow bg-white rounded-[40px] p-2 z-50",
          isMd && "flex"
        )}
      >
        <div className="flex w-full">
          <Link className="flex items-center" to="/" onClick={closeMobileMenu}>
            <img
              className="h-16 w-16"
              src="/images/logo-outline.svg"
              alt="Logomark"
            />
            <img
              className="h-5 pl-3"
              src="/images/name-outline.svg"
              alt="SANNANE"
            />
          </Link>

          {ready && !isMd && (
            <button
              className="p-2 ml-auto mr-3"
              onClick={() => {
                setIsMobileMenuOpen(!isMobileMenuOpen);
              }}
              title={isMobileMenuOpen ? "閉じる" : "メニューを開く"}
            >
              {isMobileMenuOpen ? (
                <MdClose size="2rem" />
              ) : (
                <MdMenu size="2rem" />
              )}
            </button>
          )}
        </div>

        <nav
          className={classNames("ml-auto", {
            hidden: !isMd && !isMobileMenuOpen,
          })}
        >
          <ul
            className={classNames(
              "flex justify-end items-center h-full mr-5",
              isMd ? "flex-row" : "flex-col"
            )}
          >
            <NavLi to="/" onClick={closeMobileMenu}>
              Top
            </NavLi>
            <NavLi to="/#mission" onClick={closeMobileMenu}>
              Mission
            </NavLi>
            <NavLi to="/#products" onClick={closeMobileMenu}>
              Products
            </NavLi>
            <NavLi to="/#company" onClick={closeMobileMenu}>
              Company
            </NavLi>
            <NavLi to="/#contact" onClick={closeMobileMenu}>
              Contact
            </NavLi>
          </ul>
        </nav>
      </ContentContainer>
    </header>
  );
};
