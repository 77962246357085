import React from "react";

export const ContentContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={`container mx-auto px-2 ${className || ""}`}>
      {children}
    </div>
  );
};
