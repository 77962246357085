import React from "react";

export const Word = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <span className={`inline-block ${className || ""}`}>{children}</span>;
};
