import { Helmet } from "react-helmet";
import { GlobalFooter } from "~/components/GlobalFooter";
import { GlobalHeader } from "~/components/GlobalHeader";
import { ContentContainer } from "~/components/atoms/ContentContainer";
import { Word } from "~/components/atoms/Word";

const SectionContainer = ({
  id,
  className = "",
  children,
}: {
  id?: string;
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <section id={id} className={`pt-10 -mt-10 ${className}`}>
      {children}
    </section>
  );
};

const HeroContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <div className={`py-20 ${className || ""}`}>{children}</div>;
};

const SectionTitle = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <h2 className={`text-4xl mb-10 border-b-4 inline-block ${className}`}>
      {children}
    </h2>
  );
};

const MissionItem = ({
  className = "",
  src,
  alt,
}: {
  className?: string;
  src: string;
  alt: string;
}) => {
  return (
    <div
      className={`rounded-circle bg-white aspect-w-1 aspect-h-1 ${className}`}
    >
      <img src={src} alt={alt} className="block w-3/4 h-3/4 m-auto" />
    </div>
  );
};

const CompanyProfileTableCell = ({
  children,
  className = "",
  th,
}: {
  children: React.ReactNode;
  className?: string;
  th?: boolean;
}) => {
  return th ? (
    <th className={`text-right p-2 border-r w-24 lg:w-48 ${className}`}>
      {children}
    </th>
  ) : (
    <td className={`text-left p-2 ${className}`}>{children}</td>
  );
};

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>合同会社SANNANE</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="SANNANEはみなさんの創造力やアイデアを共にカタチにすることに挑戦します。アイデアの実現に向けた技術的なコンサルティングや要件定義のお手伝いだけではなく、プロトタイプの作成、評価にも取り組んでいます。"
        />
        <meta property="og:title" content="合同会社SANNANE" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`${process.env.GATSBY_BASE_URL || "http://localhost:8000"}/`}
        />
        <meta
          property="og:image"
          content={`${
            process.env.GATSBY_BASE_URL || "http://localhost:8000"
          }/images/ogp.png`}
        />
        <meta
          property="og:description"
          content="SANNANEはみなさんの創造力やアイデアを共にカタチにすることに挑戦します。アイデアの実現に向けた技術的なコンサルティングや要件定義のお手伝いだけではなく、プロトタイプの作成、評価にも取り組んでいます。"
        />
      </Helmet>
      <GlobalHeader className="z-10" />
      <main className="pt-20 z-0">
        <SectionContainer id="top">
          <HeroContainer>
            <ContentContainer className="text-center">
              <div className="inline-block">
                <img
                  className="w-40 mb-5 mx-auto"
                  src="/images/logo-outline.svg"
                  alt="Logomark"
                />
                <img
                  className="w-80 mb-4 px-10"
                  src="/images/name-outline.svg"
                  alt="SANNANE"
                />
              </div>
              <h1>合同会社SANNANE</h1>
            </ContentContainer>
          </HeroContainer>
        </SectionContainer>
        <SectionContainer id="mission">
          <HeroContainer className="bg-cobalt text-white">
            <ContentContainer className="text-center">
              <SectionTitle className="border-white">Mission</SectionTitle>
              <p className="max-w-screen-lg mx-auto">
                SANNANEはみなさんの創造力やアイデアを共にカタチにすることに挑戦します。
              </p>
              <p className="max-w-screen-lg mx-auto mb-10">
                <Word>アイデア</Word>の実現に向けた技術的な
                <Word>コンサルティング</Word>や<Word>要件定義</Word>
                のお手伝い<Word>だけではなく、</Word>プロトタイプの作成、
                <Word>評価</Word>にも取り組んでいます。
              </p>
              <div className="grid grid-cols-3 gap-4 mx-2 md:gap-8 md:mx-10">
                <MissionItem
                  src="/images/undraw_lightbulb_moment_re_ulyo.svg"
                  alt="アイディエーション"
                />
                <MissionItem
                  src="/images/undraw_building_websites_i78t.svg"
                  alt="プロトタイピング"
                />
                <MissionItem
                  src="/images/undraw_segment_analysis_re_ocsl.svg"
                  alt="バリデーション"
                />
              </div>
            </ContentContainer>
          </HeroContainer>
        </SectionContainer>
        <SectionContainer id="products">
          <HeroContainer className="relative products-bg-left products-bg-right">
            <ContentContainer className="text-center">
              <SectionTitle className="border-cobalt">Products</SectionTitle>
              <p className="text-2xl py-20">TBD</p>
            </ContentContainer>
          </HeroContainer>
        </SectionContainer>
        <SectionContainer id="company">
          <HeroContainer className="bg-cobalt text-white">
            <ContentContainer className="text-center">
              <SectionTitle className="border-white">
                Company Profile
              </SectionTitle>
              <table className="mx-auto">
                <tbody>
                  <tr>
                    <CompanyProfileTableCell th>会社名</CompanyProfileTableCell>
                    <CompanyProfileTableCell>
                      合同会社SANNANE
                    </CompanyProfileTableCell>
                  </tr>
                  <tr>
                    <CompanyProfileTableCell th>
                      代表社員
                    </CompanyProfileTableCell>
                    <CompanyProfileTableCell>加藤 大弥</CompanyProfileTableCell>
                  </tr>
                  <tr>
                    <CompanyProfileTableCell th>設立</CompanyProfileTableCell>
                    <CompanyProfileTableCell>
                      2019年11月
                    </CompanyProfileTableCell>
                  </tr>
                  <tr>
                    <CompanyProfileTableCell th>資本金</CompanyProfileTableCell>
                    <CompanyProfileTableCell>500,000円</CompanyProfileTableCell>
                  </tr>
                  <tr>
                    <CompanyProfileTableCell th>本社</CompanyProfileTableCell>
                    <CompanyProfileTableCell>
                      神奈川県横浜市西区高島二丁目11-2スカイメナー横浜422
                    </CompanyProfileTableCell>
                  </tr>
                  <tr>
                    <CompanyProfileTableCell th>
                      事業内容
                    </CompanyProfileTableCell>
                    <CompanyProfileTableCell>
                      ソフトウェア全般企画・開発
                    </CompanyProfileTableCell>
                  </tr>
                </tbody>
              </table>
            </ContentContainer>
          </HeroContainer>
        </SectionContainer>
        <SectionContainer id="contact">
          <HeroContainer>
            <ContentContainer className="text-center">
              <SectionTitle className="border-cobalt">Contact us</SectionTitle>
              <p>お問い合わせはこちら</p>
              <a href="mailto:contact@sannane.dev">contact@sannane.dev</a>
            </ContentContainer>
          </HeroContainer>
        </SectionContainer>
      </main>
      <GlobalFooter />
    </>
  );
};

export default IndexPage;
